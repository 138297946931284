@import '../../../styles/settings.scss';

.tabs {  
    display: flex;  
  }
  .tab {  
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 2px 0;  
    border: none;
  }
  .tab:not(:last-child) {
    border-right: 1px solid gray;  
  }
  .tab.active {
    font-weight: bold;
  }
  .panel {
    display: none;
    padding: 10px;
  }
  .panel.active {
    display: block;
  }
  .course-type_span{
    background-color: #EAEAEA;
  }